$(function(){
  // イベントバインディング
  $(document).on("click", ".c-burger", function () {
    $(this).toggleClass('is-active');
    $(".l-side-header").toggleClass('is-open');
  });

  $(document).on("click", ".l-side-header a", function () {
    $(".c-burger").removeClass('is-active');
    $(".l-side-header").removeClass('is-open');
  });

  // select要素の初期設定
  $('select').css('color', 'rgba(44, 44, 44, 0.7)');  // 初期の透明度を70%に設定
  // select要素が変更された時のイベントハンドラ
  $('select').on('change', function(){
    // プレースホルダーが選択されている場合
    if($(this).val() === "placeholder"){
      $(this).css('color', 'rgba(44, 44, 44, 0.7)');  // 透明度を70%に設定
    } else {
      // 他のオプションが選択された場合
      $(this).css('color', 'rgba(44, 44, 44, 1.0)');  // 透明度を100%に設定
    }
  });
});

function splitIntoChunks(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function isOutputValidation(messageArray) {
  $('.u-error_message').empty();
  if(messageArray.length > 0) {
    messageArray.forEach(elm => {
      $("#" + elm.field).next('.u-error_message').text(elm.message);
    });
    return true;
  }

  return false;
}